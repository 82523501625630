import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/PageLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`CLHbid.com – An Innovative Platform`}</h1>
    <p>{`CLHbid.com is a leading-edge online tender platform that solves the shortcomings associated with existing methods available for Sellers of agricultural property.`}</p>
    <p>{`The team at CLHbid.com has a legal, agricultural and business background with extensive experience in buying and selling agricultural land.`}</p>
    <p>{`The goal of the CLHbid.com team was to create a custom-made technology to put in the Seller’s ‘tool box’ when selling their farm or ranch properties. In the past that tool box was limited and, for the most part, made up of multi-purpose tools analogous to the crescent wrench or pliers — good enough to get by, but never optimum for the all-important job of selling exceptional farm property, sometimes worth millions of dollars and often representing a lifetime of hard work. CLHbid.com’s technology ensures a fair bidding process for both the seller and purchaser.`}</p>
    <p>{`Each current form of selling has its own challenges. That’s why the people who created CLHbid.com firmly believe they have developed a way to buy and sell land that makes sense for the agriculture industry. Several innovations have been made that address the shortcomings of the current systems.`}</p>
    <h2>{`First and foremost, the stress of selling is removed.`}</h2>
    <p>{`Whether you’re a farmer looking to exit, an executor charged with maximizing value for an estate or an entity accountable to shareholders or taxpayers, the CLHbid.com platform is a clearing house that ensures the Seller receives market value for their land.`}</p>
    <p>{`With CLHbid.com, we eliminate long negotiation processes involving variable terms and multiple parties. Even better, there is no “neighbour against neighbour” situations that can arise because of past history, special favours, or any other reasons that potential buyers can have to influence the bidding. This takes away the stress of trying to negotiate a sale to a tenant or neighbor when no one knows the real value. You can honestly tell them, “I am not sure what it is worth, but I hope you get it”.`}</p>
    <p>{`You only sell your land once. The team at CLHbid.com has a wealth of experience in packaging land and providing custom solutions to achieve top value.`}</p>
    <h2>{`We don’t cap your sale price in advance.`}</h2>
    <p>{`Unlike a public auction, we don’t sell your land ‘regardless of price’. Rather than capping the sale price with a ‘listing price’, we set a starting bid. Most Buyers don’t like paying list price, yet don’t mind being high bidder at an auction. At CLHbid.com we have sold land well in excess of what a Seller was advised to ‘list’ it for. Unlike the old private tender in a newspaper, we allow bidders to continually up their bid, if outbid.`}</p>
    <p>{`At an unreserved public auction, having only one motivated Buyer on the day of the sale could mean that land sells for well below what one bidder is actually willing to pay. Our online platform maximizes on the number of potential bidders, which in turn ensures maximum value to the Seller. The CLHbid.com format also caters to all Buyers, whether they are seeking single parcels or the entire farm or ranch.`}</p>
    <h2>{`Sellers can make plans for their future.`}</h2>
    <p>{`Certainty in business is everything. Whether you wish to take advantage of high land prices, recent bumper crops in an area, or have concerns over changes to current tax laws, CLHbid.com gives you a set exit date that provides certainty for your planning.`}</p>
    <h2>{`Sell or buy an entire farm at once with our unique ‘En Bloc’.`}</h2>
    <p>{`On certain multi-parcel sales on CLHbid.com, once all bids are in, our ‘en bloc’ process offers only those bidders who have bid the highest on a set number of parcels to then bid against other multiple high bidders for the entire ‘en bloc’ land up for sale. To be fair to prior individual bidders, the ‘en bloc’ bidding starts at a premium amount which is in excess of the aggregate of all prior individual bids. Buyers know it is almost impossible to be high on all parcels when selling on choice, and appreciate the ‘en bloc’ option.`}</p>
    <h2>{`Buyers like the CLHbid.com process.`}</h2>
    <p>{`Giving the Buyer complete anonymity allows all Buyers to be on a level playing field. If a Buyer doesn’t know or have a relationship with the Seller it doesn’t matter. The absolute transparency of this innovative format is a huge benefit to all parties involved. Bidders bid from the privacy of their own home on a set day. The sale starts and finishes in a matter of hours.`}</p>
    <p>{`The advanced technology of the online tender platform has a refresh function on the clock removing any opportunity for a Buyer to buy cheap by waiting until near the end of the auction to bid. If outbid near the expiration of time, the clock extends the time allowing you a chance to bid again. This allows the Buyer significant time to discuss, analyze with family in the convenience of their own home or office, and continue to bid until satisfied.`}</p>
    <p><a parentName="p" {...{
        "href": "https://indd.adobe.com/view/5940eab8-9d1c-4e37-9a50-17a756413469"
      }}>{`Click this link to view our CLHbid Brochure for more information`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      